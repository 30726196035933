<template>
  <div class="index">
    <div class="content">
      <div class="title">访问趋势</div>
      <div id="myChart" class="myChart" style="width: 100%; height: 340px" />

      <a-table style="margin-top: 20px" :columns="columns" :data-source="data.list" :pagination="false" row-key="id" />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

import { commonService } from '@/service'

const service = new commonService()

export default {
  name: 'index',
  data() {
    return {
      data: {},
      columns: [
        { title: '时间', dataIndex: 'date' },
        { title: '访客数（uv）', dataIndex: 'uv' },
        { title: '访问量（pv）', dataIndex: 'pv' },
        { title: '累计访客数', dataIndex: 'cumulative_uv' },
        { title: '累计访问量', dataIndex: 'cumulative_pv' },
      ],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        const { data } = await service.getDataList({
          type: 'source',
        })
        this.data = data
        let myChart = echarts.init(document.getElementById('myChart'))
        myChart.setOption({
          legend: {
            data: ['扫码访问', '链接访问'],
          },
          grid: {
            top: '20px',
            left: '40px',
            bottom: '40px',
            right: '40px',
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.data.date,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: '扫码访问',
              data: this.data.scan,
              type: 'line',
            },

            {
              name: '链接访问',
              data: this.data.link,
              type: 'line',
            },
          ],
        })
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" scoped>
.index {
  .box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      width: calc(25% - 15px);
      height: 120px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      padding: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .icon {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        border-radius: 100%;
        text-align: center;
        width: 60px;
        height: 60px;
        line-height: 60px;
      }
      .text {
        flex: 1;
        margin-left: 20px;

        .name {
          font-size: 14px;
          color: #5c5c5c;
        }

        .value {
          font-size: 26px;
          color: #000;
        }
      }
    }
  }

  .title {
    font-size: 14px;
    color: #000;
    margin-bottom: 20px;
  }

  .mytip {
    font-size: 12px;
    color: #5c5c5c;
    float: right;
  }
  .content {
    background: #fff;
    padding: 20px;
  }
}
</style>
